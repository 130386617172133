import { Link } from "react-router-dom";

import { timeStatus, getCurrentDay } from "../../utils/Utils";

import "./DeliveryBtn.css";

const DeliveryBtn = ({ kitchenInfo }) => {
  const imageUrl = require(`../../../assets/food_images/${kitchenInfo.name}.png`);
  // const logoUrl = require(`../../../assets/logo/${kitchenInfo.name}_LOGO.png`);

  const spaceName = kitchenInfo.name.split("_").join(" ");

  let scheduleStatus = "Closed";

  const currentTime = getCurrentDay(kitchenInfo.time_zone);
  const currentRestaurantInfo = kitchenInfo.schedule_days.find(
    (x) => x.day === currentTime.currentDay
  );

  if (typeof currentRestaurantInfo !== "undefined") {
    scheduleStatus = timeStatus(
      currentRestaurantInfo.openTimeDay,
      currentRestaurantInfo.closeTimeDay,
      currentRestaurantInfo.openTimeNight,
      currentRestaurantInfo.closeTimeNight,
      currentTime.currentTime
    );
  }

  return (
    <Link
      to={kitchenInfo.url}
      target="_self"
      className="delivery-btn-wrapper"
    >
      <img
        src={imageUrl}
        alt={`${kitchenInfo.name}`}
        className="delivery-btn-img"
      />
      <div className="delivery-btn-info-wrapper">
        {/* <img
          src={logoUrl}
          alt={`${kitchenInfo.name}`}
          className="delivery-btn-logo"
        /> */}
        <div className="delivery-main-wrapper">
          <div className="delivery-main-title">{spaceName}</div>
          <div
            style={{
              color: `${scheduleStatus === "Open" ? "#36a900" : "#ea0000"}`,
            }}
          >
            {scheduleStatus}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default DeliveryBtn;
