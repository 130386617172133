import ChiiizLogo from "../../../assets/main/favicon.svg";

import "./NavBar.css";

const NavBar = (params) => {
  return (
    <div className="nav-container">
      <div className="nav-image-wrapper">
        <img src={ChiiizLogo} alt="Main logo" className="nav-image" />
      </div>
    </div>
  );
};

export default NavBar;
