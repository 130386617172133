import Otter from "../../../assets/main/OTTER Font.svg";

import "./Footer.css";

const Footer = (params) => {
  return (
    <div className="footer-wrapper">
      <div className="footer-title">BON APPÉTIT !</div>
      <div className="footer-subtext">16 RUE CASTAGNARY, 75015 PARIS</div>
      <div className="footer-text-wrapper">
        <img src={Otter} alt="Company Name" className="powered-style" />
      </div>
    </div>
  );
};

export default Footer;
