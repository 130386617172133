import { useState } from "react";
import Footer from "../utils/footer/Footer";
import DeliveryBtn from "./deliveryBtn/DeliveryBtn.js";
import DeliveryHeroImg from "./deliveryHeroImg/DeliveryHeroImg";
import CloseIcon from "@mui/icons-material/Close";

import "./DeliveryInfo.css";

import DarkFood from "../../data/darkFoodData.json";

const DeliveryInfo = () => {
  const [couponStatus, setCouponStatus] = useState(true);

  const couponStatusHandler = () => {
    setCouponStatus(false);
  };

  const couponText = "offre de bienvenue : -20% avec le code SAYCHIIIZ";
  const textHeroImg = "LES MEILLEURS RESTAURANTS Du 15ÈME, SOUS LE MÊME TOIT";
  const textHeroImgSub = "Chez Chiiiz, vous trouverez une variété de marques proposant des burgers en tous genres, des poutines très gourmandes et plein de succulents accompagnements et desserts qui raviront vos papilles ! Passez votre commande en ligne et venez récupérer votre commande à l’heure que vous avez choisie. On vous accueillera avec un grand sourire... CHIIIZ ! "

  return (
    <div>
      {couponStatus && (
        <div className="coupon-wrapper">
          <div className="coupon-wrapper-text">{couponText}</div>
          <button onClick={couponStatusHandler} className="coupon-btn">
            <CloseIcon />
          </button>
        </div>
      )}
      {/* Add extra height for the coupon so it doesn't appear over the heroImg text */}
      <div className={`${couponStatus ? "coupon-height" : ""}`}></div>
      <DeliveryHeroImg text={textHeroImg} subText={textHeroImgSub} />
      <div className="delivery-info-wrapper">
        <div className="delivery-info-btn-wrapper">
          {DarkFood.map((data) => {
            return (
              <div key={data.id}>
                <DeliveryBtn kitchenInfo={data} />
              </div>
            );
          })}
        </div>
      </div>
      <Footer />

    </div>
  );
};

export default DeliveryInfo;
